import * as React from 'react';
import { ImageType } from 'src/common/types/graphQlTypes';
import styled from 'styled-components';

const SduiImage = ({
  imageType,
  sizes,
  className,
  alt,
}: {
  imageType: ImageType;
  sizes: string;
  className?: string;
  alt?: string;
}) => {
  const imageSize = imageType.default;
  return (
    <SizedPicture>
      <source type="image/webp" srcSet={imageType.webp.srcset} sizes={sizes} />
      <source type="image/png" srcSet={imageType.default.srcset} sizes={sizes} />
      <SizedImage
        className={className}
        // allowing image alt to be overridden, specifically for the hero title element logo as we think the show title is more accurate
        alt={alt ?? imageSize.alt}
        // We default to the 768 width image in case the browser doesn't support srcsets.
        src={imageSize.w768.url}
      />
    </SizedPicture>
  );
};

export default SduiImage;

const SizedImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const SizedPicture = styled.picture`
  max-width: 100%;
  max-height: 100%;
`;
