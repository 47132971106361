export function hyphenToSpace(words: string): string {
  return words.toString().replace(/-/g, ' ');
}

/**
 * Converts a string to a readable text. It will capitalize the first letter of the string and replace all hyphens and underscores with spaces.
 */
export function toReadableText(str: string): string {
  const rv = str[0].toUpperCase() + str.slice(1).toLowerCase();
  return rv.replace(/[-_]/gi, ' ');
}
