/**
 * @module liveResource
 * @desc This module deals with fetching the live experience data
 */

import config from 'config';
import { notifyLiveStreamsLoadFail } from 'common/utils/notifications.util';
import { getStreamParams } from 'common/browser';
import {
  Maybe,
  SeekButtonModel,
  TvApiLiveExperience,
  PromoSeriesModel,
  StreamListingModel,
  Scalars,
  SwitcherRailLiveEventGroupModel,
} from 'common/types/graphQlTypes';
import { differenceInMinutes } from 'date-fns';
import { LiveExperienceData } from 'common/types/types';

type SelectedStreamListingModel = StreamListingModel & {
  selected?: Maybe<Scalars['Boolean']>;
};

export type StreamControlType = (SeekButtonModel & { onClick: () => void }) | null;

export type StreamControlsType = {
  startOverButton?: StreamControlType;
  jumpToLiveButton?: StreamControlType;
  watchFromStartButton?: StreamControlType;
};

export interface LiveResourceParamsInterface {
  slug?: string;
  offset?: number;
  device?: string;
  token?: string;
  region?: string | null;
}

export type LivePayloadType = Partial<TvApiLiveExperience> | undefined;

let payload: LivePayloadType;

// #region FETCH DATA
/**
 * Returns the endpoint URL for live experience
 * @param params - query string parameter values
 * @param apiUrl - api url that comes from client side config, if null, defaults to the static config (default staging)
 * @param isMetadataCall - if true, returns the metadata endpoint
 * @returns the endpoint URL
 */
export const getLiveEndpoint = (
  params: LiveResourceParamsInterface,
  apiUrl: string | null,
  isMetadataCall = false
): string => {
  const metadataStr = isMetadataCall ? '/metadata' : '';
  const url = new URL(`${apiUrl ?? config.apiUrl}/web${metadataStr}/live-experience`);
  const searchParams = new URLSearchParams(getQueryParams(params));
  url.search = searchParams.toString();
  return url.toString();
};

/**
 * Computes and returns the query parameters for live experience API call
 * @param params - query string parameter values
 * @returns Query parameters as an object
 */
export const getQueryParams = (params: LiveResourceParamsInterface): Record<string, string> => {
  const { offset = 0, slug = 'channel-9', token = '', region } = params;
  return {
    device: 'web',
    slug,
    streamParams: getStreamParams(), // Assuming this function returns a string
    region: region ?? 'nsw',
    offset: offset.toString(),
    ...(token && { token }), // Conditionally add the token depending on whether a token exists rather than sending an empty string with the token param
  };
};

/**
 * Fetches the live experience payload
 * @param url - the endpoint URL
 * @param notifyErrors - if true, notifies the user of any errors
 * @returns the live experience data
 */
export const fetchLiveExperience = async (url: string, notifyErrors = true): Promise<LiveExperienceData> => {
  const response = await fetch(url, { next: { revalidate: 1 } });
  const _payload = await response.json();
  if (!response.ok) {
    if (notifyErrors) notifyLiveStreamsLoadFail();
    return Promise.reject(response);
  }
  return _payload;
};

// #endregion

// #region PAYLOAD

/**
 * Creates the live payload by merging the live experience and metadata endpoint payloads.
 * This payload will be consumed by the UI components.
 */
export function createLivePayload(livePayload: LivePayloadType, metadataPayload: LivePayloadType) {
  payload = {
    ...livePayload,
    ...metadataPayload,
    // Metadata API doesn't return the Video object, so we need to merge the stream object so it wont get deleted
    stream: {
      ...livePayload?.stream,
      ...metadataPayload?.stream,
      video: {
        ...livePayload?.stream?.video,
      },
    },
  };
  return payload;
}

/**
 * When shallow rendering we need a cached copy of the payload to avoid unmounting the components when we select a card.
 * We default the stream to be empty to avoid displaying old data while the new one loads.
 */
export function createLivePayloadLoadingCache(slug: string, livePayload: LivePayloadType = payload) {
  const promoRail: Maybe<PromoSeriesModel | undefined> = payload?.promoRail;
  const stream = slug === payload?.stream?.slug ? { ...livePayload?.stream } : { display: { listings: [] } };
  if (stream?.video) {
    stream.video = undefined;
  }

  return {
    ...livePayload,
    isCached: true,
    stream,
    // If the new promo rail item selected is in the same promo rail as the previously selected one then we use the previous promo rail cache to prevent unmounting it while it's loading the new data.
    promoRail: promoRail?.items?.find((item) => item.slug === slug)
      ? promoRail
      : {
          type: 'PROMO_LIVE_EVENT_RAIL',
        },
  };
}

// #endregion

// #region ACCESSORS

/**
 * @returns the first/current item in the display listings array.
 */
export function getCurrentListing(): SelectedStreamListingModel | undefined | false {
  return Array.isArray(payload?.stream?.display?.listings) && payload?.stream?.display?.listings[0];
}

/**
 * @returns the topmost selected switcher rail item (it won't return any selected children).
 */
export function getSelectedSwitcherRailItem(): SwitcherRailLiveEventGroupModel | undefined {
  const rail = payload?.switcherRail as SwitcherRailLiveEventGroupModel[] | undefined;
  return rail?.find((card) => card.selected);
}

/**
 * @returns true if the current stream is upcoming.
 */
export function isCurrentStreamUpcoming(): boolean | undefined {
  const currentListing = getCurrentListing() as SelectedStreamListingModel | undefined;
  if (currentListing?.startDate) {
    return new Date(currentListing.startDate) > new Date();
  }
}

/**
 * @returns true if the current live event group stream has ended.
 */
export function isLiveEventGroupEnd(): boolean {
  const groupEndTime = getCurrentLiveEventGroupEndTime();
  if (groupEndTime) {
    const endTime = new Date(groupEndTime).getTime();
    const currentTime = new Date().getTime();
    return endTime <= currentTime;
  }
  return false;
}

function hasEventEnded(endDate?: string): boolean {
  if (!endDate) {
    return false;
  }

  const endTime = new Date(endDate).getTime();
  const currentTime = Date.now();

  return endTime <= currentTime;
}

export function isLiveEventEnd(): boolean {
  const currentListing = getCurrentListing() as SelectedStreamListingModel | null | undefined;

  if (payload?.stream?.type === 'live-event') {
    return hasEventEnded(currentListing?.endDate ?? undefined);
  }

  return false;
}

/**
 * @returns the end time of the currently streaming live event group.
 */
export function getCurrentLiveEventGroupEndTime() {
  const selectedRailItem = getSelectedSwitcherRailItem() as SwitcherRailLiveEventGroupModel | undefined;
  return selectedRailItem?.groupEndTime;
}

// #endregion

// #region Stream Controls

/**
 * Start-over, watch-from-start and jump-to-live buttons.
 */

export function getStartOverButtonPayload(): Maybe<SeekButtonModel> | undefined {
  return payload?.stream?.display?.buttons?.startOverButton;
}

export function getWatchFromStartButtonPayload(): Maybe<SeekButtonModel> | undefined {
  return payload?.stream?.display?.buttons?.watchFromStartButton;
}

export function getJumpToLiveButtonPayload(): Maybe<SeekButtonModel> | undefined {
  return payload?.stream?.display?.buttons?.jumpToLiveButton;
}

export function getStreamControls(): StreamControlsType {
  const startOverPayload = getStartOverButtonPayload();
  const watchFromStartPayload = getWatchFromStartButtonPayload();
  const jumpToLivePayload = getJumpToLiveButtonPayload();
  return {
    startOverButton: startOverPayload && {
      ...startOverPayload,
      onClick: startOver,
    },
    watchFromStartButton: watchFromStartPayload && {
      ...watchFromStartPayload,
      text: watchFromStartPayload.text ?? '',
      onClick: watchFromStart,
    },
    jumpToLiveButton: jumpToLivePayload && {
      ...jumpToLivePayload,
      text: jumpToLivePayload?.text ?? '',
      onClick: jumpToLive,
    },
  };
}

/**
 * Returns the offset in minutes for the given stream time.
 */
export function getStreamOffsetMinutes(time: Maybe<string | undefined>) {
  return time && differenceInMinutes(new Date(), new Date(time));
}

/**
 * Returns the start-over time for the current stream from the live experience payload.
 */
export function getStartOverTime(): Maybe<string | undefined> {
  return getStartOverButtonPayload()?.time;
}
/**
 * Returns the offset in minutes for the start-over time.
 */
export function getStartOverOffset(): number | '' | null | undefined {
  return getStreamOffsetMinutes(getStartOverTime());
}

/**
 * Plays the current stream from the start. The action is triggered through a URL update.
 */
export function startOver() {
  const offset = getStartOverOffset();
  if (typeof window !== 'undefined' && offset) {
    const url = new URL(window.location.href);
    url.searchParams.set('stream-offset', offset.toString());
    window.history.pushState({}, '', url.toString());
  }
}

/**
 * Returns the watch-from-start time from the payload for the current stream.
 */
export function getStreamStartTime() {
  return getWatchFromStartButtonPayload()?.time;
}

/**
 * Returns the offset in minutes for the current stream's watch-from-start time.
 */
export function getStartOffset() {
  return getStreamOffsetMinutes(getStreamStartTime());
}

/**
 * Triggers watch from start through a URL update.
 */
export function watchFromStart() {
  const offset = getStartOffset();
  if (typeof window !== 'undefined' && offset) {
    const url = new URL(window.location.href);
    url.searchParams.set('stream-offset', offset.toString());
    window.history.pushState({}, '', url.toString());
  }
}

/**
 * Moves current stream to live time. The action is triggered through a URL update.
 */
export function jumpToLive() {
  if (typeof window !== 'undefined') {
    const url = new URL(window.location.href);
    url.searchParams.set('stream-offset', '0');
    window.history.pushState({}, '', url.toString());
  }
}

/**
 * Returns the stream offset value from the URL if present.
 */
export function getStreamOffsetParam(): number | undefined {
  if (typeof window !== 'undefined') {
    const url = new URL(window.location.href);
    const offset = url.searchParams.get('stream-offset');
    if (offset) {
      return parseFloat(offset);
    }
  }
}

/**
 * Returns the stream offset value from the payload if present.
 */
export function getOffset() {
  return payload?.stream?.display?.offset;
}

/**
 * Returns the stream offset value from the URL or payload respectively.
 */
export function getStreamOffset() {
  return getStreamOffsetParam() ?? getOffset();
}

// #endregion
