import styled from 'styled-components';
import { MetadataElement } from 'src/common/types/graphQlTypes';
import SduiMetadataProgress from 'components/sduiMetadataProgress/SduiMetadataProgress';
import { FlexBreak } from 'styles/styledComponents';
import classNames from 'classnames';
import SduiInlineTextAndIconElement from '../sduiInlineTextAndIconElement/SduiInlineTextAndIconElement';
import SduiImage from '../sduiImage/SduiImage';

export type SduiMetadataElementType = {
  metadataElement: MetadataElement;
  className?: string;
  truncate?: boolean;
};

const SduiMetadataElement = ({
  metadataElement: { metadata, image, progress },
  className,
  truncate,
}: SduiMetadataElementType) => (
  <SduiMetadataElementSection
    className={classNames(className, 'sdui_metadata__element_section')}
    truncate={truncate}
  >
    {image && <StyledSduiImage imageType={image} sizes="50vw" />}
    {metadata.map((e) => (
      <SduiInlineTextAndIconElement key={e.id} inlineTextAndIconElement={e} truncate={truncate} />
    ))}
    {progress && <FlexBreak />}
    {progress && <SduiMetadataProgress metadataProgress={progress} />}
  </SduiMetadataElementSection>
);

export default SduiMetadataElement;

export const SduiMetadataElementSection = styled.section<{ truncate?: boolean }>`
  display: flex;
  ${(props) => (props.truncate ? 'flex-wrap: nowrap;' : 'flex-wrap: wrap;')}
  align-items: center;
  justify-content: center;
  margin-bottom: var(--space-xsmall);

  // On widths between 900px and 999px, if the viewport is landscape, the Hero should be centred
  // This media query is equivalent to up('tabletLandscape') but excluding the case above
  @media (min-width: 900px) and (max-width: 999px) and (orientation: portrait), (min-width: 1000px) {
    justify-content: flex-start;
    > * {
      &:first-child {
        margin-left: 0;
      }
    }
  }
`;

const StyledSduiImage = styled(SduiImage)`
  /* TODO: Breeny has told me that Metadata elements may not even have images like
    this. We need to confirm this. Because this image is an SVG, if the height is 
    not set, it becomes very big, so the height is being set here to stop that. 
    This needs to be revisted in the future. */
  height: 1rem;
  margin: var(--space-inline-small);
`;
